import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { Slider } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import styles from './Calculator.module.css';

const useStyles = makeStyles({
    horizontal: {
      width: 300,
      margin: '0 auto'
    }
  })
  
  const CustomSlider = withStyles({
    root: {
      color: '#1a8977',
      height: 8,
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      marginTop: -8,
      marginLeft: -12,
      '&:focus, &:hover': {
        boxShadow: '0px 0px 0px 8px rgba(84, 199, 97, 0.16)'
      },
      '&$active': {
        boxShadow: '0px 0px 0px 12px rgba(84, 199, 97, 0.16)'
      }
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 4px)'
    },
    track: {
      height: 10,
      borderRadius: 4
    },
    rail: {
      height: 10,
      borderRadius: 4
    },
    mark: {
        display: 'none'
    },

  })(Slider)

const Calculator = () => {
    const classes = useStyles();
    const [squareMeters, setSquareMeters] = useState(1);
    const [totalPrice, setTotalPrice] = useState(0);
    const [monthlyPrice, setMonthlyPrice] = useState(0);

    useEffect(() => {
        const calculatePrice = (squareMeters) => {
            let pricePerBox = squareMeters * 199; 
            let discount = 0;

            if (squareMeters >= 3 && squareMeters < 5) {
              discount = 100;
            } else if (squareMeters >= 5) {
              discount = 300;
            }

            const sum = pricePerBox - discount;
            return Math.round(sum * 0.75);
        };

        const price = calculatePrice(squareMeters);
        setTotalPrice(price * 8);
        setMonthlyPrice(price);
    }, [squareMeters]);

    return (
      <div className={styles.inline}>
        <div className={styles.calculatorContainer}>
           <div className={styles.inline}>
             <p className={styles.title}>
              Legg ut hagen din på Nabohage
            </p>
            </div>
            <p className={styles.subtitle}>
                Du kan tjene
            </p>
            <p className={styles.totalPrice}>
                {totalPrice} NOK i året
            </p>
            <p className={styles.monthlyPrice}>
                {monthlyPrice} NOK i måneden
            </p>
            <Box className={styles.sliderContainer}>
                <p id="square-meters-slider" className={styles.sliderLabel}>
                Hvor mange kvadratmeter ønsker du å leie ut?
                </p>
                <CustomSlider
                  value={squareMeters}
                  onChange={(e, newValue) => setSquareMeters(newValue)}
                  aria-labelledby="square-meters-slider"
                  valueLabelDisplay="auto"
                  min={1}
                  max={200}
                  classes={{
                    thumb: classes.thumb,
                    track: classes.track,
                    rail: classes.rail,
                  }}
                />
                <p className={styles.valueDisplay}>
                    Kvm: {squareMeters}
                </p>
            </Box>
            <p className={styles.Disclaimer}>
              Dette er bare et estimat og endringer kan forekomme.
            </p>
        </div>
        </div>
       
    );
};

export default Calculator;